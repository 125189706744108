import React from 'react';
// interfaces
import { IImage } from '../../interfaces/image';
// components
import BackgroundImage from '../background-image';
import Image from '@components/image/image';
// styles
import styles from './section-standard.module.scss';

interface IProps {
  direction?: string;
  layout?: string;
  background?: string;
  backgroundImage?: IImage;
  title?: string;
  description?: string;
  list?: Array<any>;
  image?: IImage;
  imageSize?: string;
  footnote?: any;
  buttonLabel?: string;
  children?: any;
  buttonAction?: () => void;
  heroSection?: boolean;
  customClass?: string;
}

const SectionStandard: React.FC<IProps> = ({
  direction = 'Left',
  layout = '',
  background = '',
  backgroundImage,
  title,
  description,
  list,
  image,
  imageSize = 'Large',
  footnote,
  buttonLabel,
  children,
  buttonAction,
  heroSection = false,
  customClass,
}) => {
  const heroBackground = background === 'HeroWhite' ? styles.sectionHeroWhite : styles.sectionHero;

  return (
    <section
      className={`${customClass ? customClass : ''} ${heroSection ? heroBackground : styles['section' + background]}`}
    >
      {backgroundImage && <BackgroundImage image={backgroundImage} />}
      <div className={heroSection ? styles.heroContainer : styles.container}>
        <div className={heroSection ? styles.heroRow : styles.row}>
          <div className={styles['image' + direction + imageSize]}>
            {children}

            {image && image.filename_disk && (
              <Image width={imageSize === 'Small' ? 264 : 800} filename={image.filename_disk} />
            )}
          </div>
          <div className={styles['contentWrapper' + layout + direction]}>
            <div className={heroSection ? styles.heroContent : styles.content}>
              {heroSection ? <h1>{title}</h1> : <h2>{title}</h2>}
              {title && description && <hr className={styles.hr} />}
              {description && (
                <div
                  className={heroSection ? styles.heroDescription : ''}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              )}
              {list && (
                <ul className={styles.list}>
                  {list.map((item: any, i: number) => (
                    <li key={i}>
                      <p className={styles.listItemTitle}>{item.title}</p>
                      <p>{item.description}</p>
                    </li>
                  ))}
                </ul>
              )}
              {buttonAction && (
                <button className={styles.button} type="button" onClick={buttonAction}>
                  {buttonLabel}
                </button>
              )}
              {footnote && <div className={styles.footnote} dangerouslySetInnerHTML={{ __html: footnote }} />}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionStandard;
