import { graphql, useStaticQuery } from 'gatsby';

export const usePersonalizedPlus2WeekText = () => {
  const { landing, home, directusSetting, site } = useStaticQuery(graphql`
    query Landing {
      landing: directusPersonalisedPlus2WeekTrial {
        page_title
        page_description
        hero_title
        hero_description
        hero_background_image {
          filename_disk
        }
        hero_mobile_background_image {
          filename_disk
        }
        hero_button_label
        partner_logos {
          image_hash
        }
        how_it_works_title
        how_it_works_items {
          title
          description
          filename_disk
        }
        reviews_title
        reviews_items {
          reviewerName
          review
        }
        expert_approval_title
        expert_approval_description
        faqs_title
        faqs_description
        faqs_items {
          question
          answer
        }
        personal_title
        personal_description
        personal_items {
          image_hash_desktop
          image_hash_mobile
        }
        vitamins_title
        vitamins_slides {
          name
          icon_filename_disk
          image_filename_disk
        }
        vitamins_button_label
        vitamins_footnote
      }
      home: directusHome {
        experts {
          name
          qualification
          role
          image {
            title
            filename_disk
          }
        }
      }
      directusSetting {
        logo {
          title
          filename_disk
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return {
    landing,
    home,
    directusSetting,
    site,
  };
};
